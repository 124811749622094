import React from 'react';
import {Line} from 'react-chartjs-2';
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import {IStatisticGraph} from "../../app/newsletter/adminNewsletterStatistic";

// Register the necessary Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface LineChartData {
    labels: string[]; // X-axis labels
    datasets: Dataset[];
}

interface Dataset {
    label: string; // Dataset label
    data: number[]; // Data points for the line chart
    borderColor: string; // Line color
    backgroundColor: string; // Background color of the line area
    fill: boolean; // Whether the area under the line is filled
    tension: number; // Line smoothing (0 for straight lines)
}

interface IProps {
    chartData: IStatisticGraph
}

function transformDataToLineChart(input: IStatisticGraph) {
    // Prepare labels and datasets
    const labels = [];
    const openRateData = [];
    const clickRateData = [];

    // Iterate through input and populate labels and datasets
    for (const date in input) {
        if (input.hasOwnProperty(date)) {

            labels.push(date);
            openRateData.push(input[date].averageOpenRate);
            clickRateData.push(input[date].averageClickRate);
        }
    }

    return {
        labels,
        datasets: [
            {
                label: 'Average Open Rate',
                data: openRateData,
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                fill: true,
                tension: 0.4,
            },
            {
                label: 'Average Click Rate',
                data: clickRateData,
                borderColor: 'rgba(153, 102, 255, 1)',
                backgroundColor: 'rgba(153, 102, 255, 0.2)',
                fill: true,
                tension: 0.4,
            },
        ],
    };
}

const LineChart: React.FC<IProps> = ({chartData}) => {
    // Chart data
    const data: LineChartData = transformDataToLineChart(chartData)

    // Chart configuration options
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top', // Position of the legend
            },
            title: {
                display: false,
                text: 'Newsletter statistics', // Chart title
            },
        },
        scales: {
            x: {
                beginAtZero: true,
            },
            y: {
                beginAtZero: true,
            },
        },
    };

    return (
        <div style={{width: '100%'}}>
            <Line data={data} options={options as any}/>
        </div>
    );
};

export default LineChart;
