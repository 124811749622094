import {Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import {ConfirmDelete} from "../../../shared/components/ConfirmDelete";
import {AdminMagazineService} from "../service";
import {ErrorToast, SuccessToast} from "../../../shared/toasters/toasters";
import {Loader} from "../../../shared/components/Loader";
import {EmptyState} from "../../../shared/components/EmptyState";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";

export const List = ({
                         list,
                         pagination,
                         onTableHeaderClick,
                         setTriggerUpdate,
                         editHandler,
                         ctaHeaderBtnClick,
                         ctaHeaderBtnTitle
                     }: any) => {
    const {t} = useTranslation();
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [selectedPrompt, setselectedPrompt] = useState<any>();
    const {magazinesOrderByColumn} = useSelector((state: RootState) => state.enum);

    const deleteHandler = () => {
        selectedPrompt?.id &&
        AdminMagazineService.delete(selectedPrompt?.id)
            .then((response) => {
                if (response?.status === "success") {
                    SuccessToast(t(`magazine.successfully_deleted`));
                    setTriggerUpdate((prev: boolean) => !prev);
                }
            })
            .catch((error) => ErrorToast(error));
    };
    return (
        <div className="card">
            <div className="card-header pb-0">
                <div className="d-flex justify-content-between">
                    <h4 className="card-title mg-b-0">{t("magazine.listOfMagazines")}</h4>
                    {ctaHeaderBtnClick && (
                        <button
                            className={"btn btn-primary btn-sm mb-2"}
                            onClick={ctaHeaderBtnClick}
                        >
                            {ctaHeaderBtnTitle}
                        </button>
                    )}
                </div>
            </div>
            <div className="card-body">
                {list === undefined ? (
                    <Loader/>
                ) : list.length === 0 ? (
                    <EmptyState/>
                ) : (
                    <div className="table-responsive">
                        <Table className="align-middle table-nowrap mb-0">
                            <thead className="table-light">
                            <tr className="">
                                {magazinesOrderByColumn?.map((header) => (
                                    <th scope="col" key={header} className={onTableHeaderClick && 'cursor-pointer'}
                                        onClick={() => onTableHeaderClick && onTableHeaderClick(header)}>
                                        {t(`global.${header}`)}
                                        {pagination?.orderBy === header ? <>
                                            {pagination?.orderDirection === 'asc' ?
                                                <i className="bi bi-sort-alpha-up"></i> :
                                                <i className="bi bi-sort-alpha-down"></i>}
                                        </> : ''}
                                    </th>
                                ))}
                                <th scope="col">{t(`table_headers.actions`)}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {list?.map((prompt: any) => {
                                return (
                                    <tr key={prompt?.id} className="">
                                        <td>
                                            <Link to="#" className="fw-medium">
                                                #{prompt?.id}
                                            </Link>
                                        </td>
                                        <td>{prompt?.title ?? "-"}</td>
                                        <td>{prompt?.subtitle ?? "-"}</td>
                                        <td>{prompt?.category?.name ?? "-"}</td>
                                        <td
                                            className={`text-${
                                                prompt?.isActive ? "success" : "danger"
                                            }`}
                                        >
                                            {prompt?.isActive ? (
                                                <span>
                            <i className="ri-checkbox-circle-line fs-17 align-middle"/>{" "}
                                                    {t(`global.active`)}
                          </span>
                                            ) : (
                                                <span>
                            <i className="ri-close-circle-line fs-17 align-middle"/>
                                                    {t(`global.inactive`)}
                          </span>
                                            )}
                                        </td>
                                        <td
                                            className={`text-${
                                                prompt?.showOnShelf ? "success" : "danger"
                                            }`}
                                        >
                                            {prompt?.showOnShelf ? (
                                                <span>
                            <i className="ri-checkbox-circle-line fs-17 align-middle"/>{" "}
                                                    {t(`global.yes`)}
                          </span>
                                            ) : (
                                                <span>
                            <i className="ri-close-circle-line fs-17 align-middle"/>
                                                    {t(`global.no`)}
                          </span>
                                            )}
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <button
                                                    className="btn btn-sm btn-warning d-flex align-items-center me-2"
                                                    onClick={() => editHandler(prompt?.id)}
                                                >
                                                    <i className="ri-edit-2-fill me-1"/>
                                                    {t("global.edit")}
                                                </button>
                                                <button
                                                    className="btn btn-sm btn-danger d-flex align-items-center"
                                                    onClick={() => {
                                                        handleShow();
                                                        setselectedPrompt(prompt);
                                                    }}
                                                >
                                                    <i className="ri-delete-bin-fill me-1"/>
                                                    {t("global.delete")}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </Table>
                    </div>
                )}

                {/* <div className="d-flex justify-content-end ">
          {totalPages <= 1 ? (
            ""
          ) : (
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePagination={handlePages}
            />
          )}
        </div> */}
                <ConfirmDelete
                    show={show}
                    setShow={setShow}
                    itemName={selectedPrompt?.title}
                    deleteHandler={deleteHandler}
                    product={selectedPrompt}
                    selectedProduct={selectedPrompt}
                />
            </div>
        </div>
    );
};
