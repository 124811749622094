import {NEWSLETTER_URL} from "../../helpers/api.routes";
import {requests} from "../../helpers/api.services";

export const AdminNewsLetterService = {
    getAll: (params: any) => requests.get(`${NEWSLETTER_URL}/step1`, params),
    getSingle: (id: number) => requests.get(`${NEWSLETTER_URL}/step1/${id}`),
    update: (params: any) =>
        requests.put(`${NEWSLETTER_URL}/step1/${params.id}`, params),
    create: (params: any) =>
        requests.post(`${NEWSLETTER_URL}/create`, params),
    preview: (params: any) => requests.post(`${NEWSLETTER_URL}/preview`, params),

    delete: (id: number) => requests.delete(`${NEWSLETTER_URL}/step1/${id}`),
    generateStatistic: (params: { toDate: string | undefined, fromDate: string | undefined }) =>
        requests.get(`${NEWSLETTER_URL}/statistic/data`, params)
};
